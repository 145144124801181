import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>S+J Schedule</title>
            <meta name="description" content="Schedule" />
        </Helmet>

        <div id="main" className="alt schedule">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Schedule of Events</h1>
                    </header>

                    <h2>Friday - 6/14</h2>
                    <h3><span role="img" aria-label="pig">🐷</span> Welcome BBQ! </h3>

                    <div className="scheduleRow">
                    <p className="time">6pm - 9pm</p>
                    <p>Dress: Casual </p>
                    <p>Location: Seven Ponds Farm</p>

                    </div>
                    <hr/>
                    <h2>Saturday - 6/15</h2>
            <h3>❤ Wedding Day  </h3>

                    <div className="scheduleRow">
                <h4>Ceremony</h4>
            <p className="time">5pm</p>
        <p>Dress: Farm Chic (this is a thing now)</p>
        <p>Location: Seven Ponds Farm</p>

</div>

                    <div className="scheduleRow">

        <h4>Cocktail Hour <span role="img" aria-label="cocktail">🍸</span>  and Reception <span role="img" aria-label="dancing">💃</span> to follow</h4>
        <p className="time">6pm - ∞</p>


</div>
<hr/>
                    <h2>Sunday - 6/16</h2>


<h3><span role="img" aria-label="coffee">☕ </span> Goodbye Brunch (and Happy Father’s Day!)</h3>

                    <div className="scheduleRow">
<p className="time">10am</p>
 <p>Location: Seven Ponds Farm</p>
<p>Come by for breakfast, or at least some coffee, before hitting the road!</p>

</div>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic